import styled from '@emotion/styled';
import { Button } from 'react-bootstrap';

export const ColoredButton = styled(Button)`
    color: #ffffff;
    border-radius: 100rem;
    background-image: linear-gradient(91deg, #ffa66d 21%, #f8528d 107%);
    border: solid 1px transparent;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-right: 2rem;
    border-style: solid;
    border-width: 0.5px;
`;

export const BlueColoredButton = styled(Button)`
    color: #ffffff;
    border-radius: 100rem;
    background-image: linear-gradient(to top, #9ba4d9, #94dfe7);
    border: solid 1px transparent;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-right: 2rem;
    border-style: solid;
    border-width: 0.5px;
`;

export const StyledButton = styled.button`
    border-radius: 100rem;
    color: #29558c;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-right: 2rem;
    border-style: solid;
    border-width: 0.5px;
    border-image-source: linear-gradient(to bottom, #ffa66d, #f8528d);
    border-image-slice: 1;
    background-image: linear-gradient(to bottom, #ffffff, #ffffff),
        linear-gradient(to bottom, #ffa66d, #f8528d);
    background-origin: border-box;
    background-clip: content-box, border-box;
    border: solid 1px transparent;
    box-shadow: 2px 1000px 1px #fff inset;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    &:hover {
        color: black;
    }
`;
