import React, { ReactNode } from 'react';
import { Navigation } from '.';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './Footer';

interface LayoutProps {
    children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => (
    <>
        <div>
            <header>
                <Navigation />
            </header>
            <main>{children}</main>
            <footer>
                <Footer />
            </footer>
        </div>
    </>
);

export default Layout;
