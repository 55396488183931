import styled from '@emotion/styled';
import { PaperAirplaneIcon } from '@primer/octicons-react';
import { Link } from 'gatsby';
import React, { ReactElement } from 'react';
import { Col, Container, Row, InputGroup, FormControl, Button } from 'react-bootstrap';

const StyledDivSection = styled.div`
    padding: 0.5rem;
    color: #7a7a7a;
`;

const StyledLink = styled(Link)`
    color: #7a7a7a;
    :hover {
        text-decoration: none;
    }
`;

const StyledFooterColumn = styled(Col)`
    border: solid 0.5px #979797;
    background-image: linear-gradient(to left, #50a9f3, #23569d);
    height: 5rem;
    color: #bfbfbf;
    align-items: center;
    justify-content: center;
    display: flex;
`;

const Footer = (): ReactElement => (
    <>
        <Container>
            <Row noGutters style={{ marginTop: `2rem`, marginBottom: `2rem` }}>
                <Col lg="3" md="12">
                    <StyledDivSection style={{ paddingTop: `3rem`, fontSize: `0.9rem`, width: `16rem` }}>
                        The Slogan of your company or Too short description of
                        company.
                    </StyledDivSection>
                    <div style={{
                        display: `flex`,
                        alignItems: 'center',
                        marginTop: `0.5rem`,
                        width: `16rem`,
                        height: `3rem`,
                        borderRadius: `3px`,
                        border: `solid 0.5px #f8e1f1`,
                        backgroundColor: `#f8f7f8`}}>
                            <div><img style={{height: `2rem`, marginLeft: `1rem`, width: `2rem`}} src="images/icons/instagram.svg" /></div>
                            <div style={{fontWeight: `bold`, marginLeft: `1rem`, color: `#7c5673`}}>Join Us on Instagram</div>
                    </div>
                </Col>
                <Col lg="2" md="12">
                    <StyledDivSection style={{ fontWeight: `bold` }}>
                        Product
                    </StyledDivSection>
                    <StyledDivSection>
                        <StyledLink to="/product-packages">Products</StyledLink>
                    </StyledDivSection>
                    <StyledDivSection>
                        <StyledLink to="/features-packages">
                            Features
                        </StyledLink>
                    </StyledDivSection>
                    <StyledDivSection>
                        <StyledLink to="/pricing">Pricing</StyledLink>
                    </StyledDivSection>
                    <StyledDivSection>Testimonies</StyledDivSection>
                </Col>
                <Col lg="2" md="12">
                    <StyledDivSection style={{ fontWeight: `bold` }}>
                        Information
                    </StyledDivSection>

                    <StyledDivSection>
                        <StyledLink
                            style={{ textDecoration: `none` }}
                            to="/faq"
                        >
                            FAQ
                        </StyledLink>
                    </StyledDivSection>
                    <StyledDivSection>Help</StyledDivSection>
                    <StyledDivSection>
                        <StyledLink to="/contact-us">Contact Us</StyledLink>
                    </StyledDivSection>
                </Col>
                <Col lg="2" md="12">
                    <StyledDivSection style={{ fontWeight: `bold` }}>
                        Company
                    </StyledDivSection>
                    <StyledDivSection>
                        <StyledLink to="/about-us">About Us</StyledLink>
                    </StyledDivSection>
                    <StyledDivSection>
                        <StyledLink to="/blog">Blog</StyledLink>
                    </StyledDivSection>
                    <StyledDivSection>Terms of Services</StyledDivSection>
                    <StyledDivSection>Privacy Policy</StyledDivSection>
                </Col>
                <Col lg="3" md="12">
                    <StyledDivSection style={{ fontWeight: `bold` }}>
                        SUBSCRIBE
                    </StyledDivSection>
                    <InputGroup>
                        <FormControl
                        placeholder="Your email"
                        aria-label="Your email"
                        aria-describedby="email-addon"
                        />
                        <InputGroup.Append>
                            <Button variant="outline-secondary"><PaperAirplaneIcon size={16} /></Button>
                        </InputGroup.Append>
                    </InputGroup>
                    <StyledDivSection>
                        We’ll never share your email address with a third-party.
                    </StyledDivSection>
                </Col>
            </Row>
        </Container>
        <StyledFooterColumn>
            <div>Copyright © 2020 StackIAM - All Right Reserved</div>
        </StyledFooterColumn>
    </>
);

export default Footer;
