import React, { ReactElement } from 'react';
import styled from '@emotion/styled';
import { Navbar, Container, Nav } from 'react-bootstrap';
import { Link } from 'gatsby';
import { StyledButton } from './Buttons';

const NavbarWrapper = styled(Navbar)`
    padding: 1rem 0;
    border: solid 0.5px #f2f2f2;
`;

const StyledNav = styled(Link)`
    color: #29558c;
    text-decoration: none;
    :hover {
        text-decoration: none;
    }
`;

const Navigation = (): ReactElement => (
    <Container>
        <div style={{ backgroundColor: `#fff9f5`, height: `0.5rem` }}></div>
        <NavbarWrapper variant="light" fixed="top" sticky="top" expand="lg">
            <Navbar.Brand
                href="#home"
                style={{ paddingLeft: `3rem` }}
                className="App-logo"
            >
                <Link to="/">
                    <img src="/images/brand@2x.png" alt="" />
                </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link
                        href="#features"
                        style={{
                            color: `#29558c`,
                            paddingLeft: `3rem`,
                            paddingRight: `1.5rem`,
                        }}
                    >
                        <StyledNav to="/features-packages">Features</StyledNav>
                    </Nav.Link>
                    <Nav.Link
                        style={{
                            color: `#29558c`,
                            paddingLeft: `1.5rem !important`,
                            paddingRight: `1.5rem !important`,
                        }}
                        href="#products"
                    >
                        <StyledNav to="/product-packages">Products</StyledNav>
                    </Nav.Link>
                    <Nav.Link
                        style={{
                            color: `#29558c`,
                            paddingLeft: `1.5rem`,
                            paddingRight: `1.5rem`,
                        }}
                        href="#pricing"
                    >
                        <StyledNav to="/pricing">Pricing</StyledNav>
                    </Nav.Link>
                </Nav>
                <StyledNav className="ml-auto" to="/login">
                    Log in
                </StyledNav>
                <Nav style={{ paddingLeft: `3rem`, paddingRight: `5rem` }}>
                    <StyledButton>
                        <StyledNav style={{ color: `#29558c` }} to="/signup">
                            Sign up
                        </StyledNav>
                    </StyledButton>
                </Nav>
            </Navbar.Collapse>
        </NavbarWrapper>
    </Container>
);

export default Navigation;
